import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';
import PersonaCard from './PersonaCard';
import Modal from '../Modal';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { Bar, Pie } from 'react-chartjs-2';
import Logo from '../icons/logo';
import 'chart.js/auto';
import { 
    DeleteIcon, 
    HomeIcon, 
    IntegrationsIcon, 
    KeyInsightsIcon, 
    OverviewIcon, 
    PersonaIcon, 
    SessionsIcon 
} from '../icons';
import InsightEditor from './InsightEditor';


function InterviewDetail({ auth }) {
    const { interviewId } = useParams();
    const [interview, setInterview] = useState(null);
    const [completedSessions, setCompletedSessions] = useState(0);
    const [processedTranscripts, setProcessedTranscripts] = useState('');
    const [personas, setPersonas] = useState([]);
    const [insights, setInsights] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [showAllKeywords, setShowAllKeywords] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('overview');
    const [isGeneratingInsights, setIsGeneratingInsights] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [demographicData, setDemographicData] = useState({
        ageRanges: {},
        cities: {},
        occupations: {}
    });

    const chartInitialState = {
        labels: [],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1
        }]
    };

    const [ageData, setAgeData] = useState(chartInitialState);
    const [cityData, setCityData] = useState(chartInitialState);
    const [occupationData, setOccupationData] = useState(chartInitialState);

    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
    const [sessionFilter, setSessionFilter] = useState('all');
    const [redditUrl, setRedditUrl] = useState('');
    const [isAddingReddit, setIsAddingReddit] = useState(false);
    const [selectedDataSources, setSelectedDataSources] = useState({
        interviews: true,
        reddit: false
    });
    const [isRedditModalOpen, setIsRedditModalOpen] = useState(false);
    const [selectedRedditThread, setSelectedRedditThread] = useState(null);
    const [isDataSourcesModalOpen, setIsDataSourcesModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [availableTags, setAvailableTags] = useState([
        'positive feedback',
        'concern',
        'feature request',
        'bug report',
        'suggestion',
        'usability issue'
    ]);

    const navItems = [
        { id: 'overview', label: 'Overview', icon: <OverviewIcon className="w-6 h-6" color="currentColor" /> },
        { id: 'keyInsights', label: 'Key Insights', icon: <KeyInsightsIcon className="w-6 h-6" color="currentColor" /> },
        { id: 'personas', label: 'Personas', icon: <PersonaIcon className="w-6 h-6" color="currentColor" /> },
        { id: 'integrations', label: 'Integrations', icon: <IntegrationsIcon className="w-6 h-6" color="currentColor" /> },
        { id: 'sessions', label: 'Sessions', icon: <SessionsIcon className="w-6 h-6" color="currentColor" /> },
    ];

    const [projectInterviews, setProjectInterviews] = useState([]);

    const [sentimentData, setSentimentData] = useState({
        labels: ['Positive', 'Negative', 'Neutral'],
        datasets: [{
            data: [0, 0, 0],
            backgroundColor: ['#4CAF50', '#F44336', '#FFC107'],
            hoverBackgroundColor: ['#66BB6A', '#EF5350', '#FFCA28']
        }]
    });

    useEffect(() => {
        if (selectedSession) {
            const sentimentCounts = { positive: 0, negative: 0, neutral: 0 };
            selectedSession.messages.forEach(message => {
                sentimentCounts[message.sentiment] += 1;
            });

            setSentimentData({
                ...sentimentData,
                datasets: [{
                    ...sentimentData.datasets[0],
                    data: [
                        sentimentCounts.positive,
                        sentimentCounts.negative,
                        sentimentCounts.neutral
                    ]
                }]
            });
        }
    }, [selectedSession]);

    const renderSentimentChart = () => (
        <div className="p-4 bg-white rounded-lg shadow mt-4">
            <h3 className="text-lg font-bold mb-2">Sentiment Analysis</h3>
            <Pie data={sentimentData} />
        </div>
    );

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/interviews/${interviewId}/details`)
            .then(response => {
                if (isMounted) {
                    console.log('Interview details:', response.data);
                    setInterview(response.data);
                    setCompletedSessions(response.data.completedSessions);
                    if (response.data.insights) {
                        setInsights(response.data.insights);
                    }
                }
            })
            .catch(error => {
                console.error('Failed to fetch interview details:', error);
            });

        // Fetch personas
        axios.get(`/api/interviews/${interviewId}/personas`)
            .then(response => {
                if (isMounted) {
                    setPersonas(response.data);
                }
            })
            .catch(error => {
                console.error('Failed to fetch personas:', error);
            });

        return () => { isMounted = false; };
    }, [interviewId]);

    useEffect(() => {
        axios.get(`/api/interviews/${interviewId}/sessions/processed`)
            .then(response => {
                const allMessages = response.data.map(session => session.messages.join(' ')).join('\n');
                setProcessedTranscripts(allMessages);
            })
            .catch(error => {
                console.error('Failed to fetch processed sessions:', error);
            });
    }, [interviewId]);

    useEffect(() => {
        const fetchDemographicData = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}/sessions/demographics`);
                setDemographicData(response.data);
                
                // Update chart data
                setAgeData({
                    labels: Object.keys(response.data.ageRanges),
                    datasets: [{
                        label: 'Age Distribution',
                        data: Object.values(response.data.ageRanges),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }]
                });

                setOccupationData({
                    labels: Object.keys(response.data.occupations),
                    datasets: [{
                        label: 'Occupation Distribution',
                        data: Object.values(response.data.occupations),
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1
                    }]
                });

                setCityData({
                    labels: Object.keys(response.data.cities),
                    datasets: [{
                        label: 'City Distribution',
                        data: Object.values(response.data.cities),
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }]
                });
            } catch (error) {
                console.error('Failed to fetch demographic data:', error);
                setAlert({ message: 'Failed to load demographic data', type: 'error' });
            }
        };

        if (interviewId) {
            fetchDemographicData();
        }
    }, [interviewId]);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}/sessions`);
                setSessions(response.data);
            } catch (error) {
                console.error('Failed to fetch sessions:', error);
                setAlert({ message: 'Failed to load sessions', type: 'error' });
            }
        };

        if (interviewId) {
            fetchSessions();
        }
    }, [interviewId]);

    useEffect(() => {
        if (interview?.projectId?._id) {
            axios.get(`/api/projects/${interview.projectId._id}/interviews`)
                .then(response => {
                    setProjectInterviews(response.data);
                })
                .catch(error => {
                    console.error('Failed to fetch project interviews:', error);
                });
        }
    }, [interview]);

    const handleCopyLink = () => {
        const chatbotLink = `${process.env.REACT_APP_CHAT_PLATFORM_URL}/chat/${interviewId}`;

        navigator.clipboard.writeText(chatbotLink)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch(error => {
                console.error('Failed to copy chatbot link to clipboard:', error);
                setAlert({ message: 'Failed to copy link', type: 'error' });
            });
    };

    const generatePersona = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/api/generate-framework', { combinedText: processedTranscripts });
            const personaData = response.data;
            await savePersona(personaData);
            setAlert({ message: 'Persona generated and saved successfully!', type: 'success' });
        } catch (error) {
            console.error('Failed to generate persona:', error);
            setAlert({ message: 'Failed to generate persona', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const generateInsights = async () => {
        if (!Object.values(selectedDataSources).some(v => v)) {
            setAlert({
                message: 'Please select at least one data source',
                type: 'error'
            });
            return;
        }

        setIsGeneratingInsights(true);
        try {
            console.log('Generating insights for interview:', interviewId);
            const response = await axios.post(`/api/generate-insights/${interviewId}`, {
                selectedDataSources
            });

            if (response.data.insights) {
                setInterview(prev => ({
                    ...prev,
                    insights: response.data.insights
                }));

                setAlert({
                    message: 'Insights generated successfully',
                    type: 'success'
                });
                
                setActiveTab('insights');
            } else {
                throw new Error('No insights returned from server');
            }
        } catch (error) {
            console.error('Failed to generate insights:', error);
            setAlert({
                message: error.response?.data?.error || 'Failed to generate insights',
                type: 'error'
            });
        } finally {
            setIsGeneratingInsights(false);
        }
    };

    const savePersona = async (personaData) => {
        try {
            const saveResponse = await axios.post('/api/save-persona', {
                ...personaData,
                interviewId,
                transcriptIds: []
            });
            setPersonas([...personas, saveResponse.data]);
        } catch (error) {
            console.error('Failed to save persona:', error);
            setAlert({ message: 'Failed to save persona', type: 'error' });
        }
    };

    const saveInsights = async (insightsData) => {
        try {
            const response = await axios.post(`/api/interviews/save-insights/${interviewId}`, { insights: insightsData });
            console.log('Insights saved:', response.data);
        } catch (error) {
            console.error('Failed to save insights:', error);
            setAlert({ message: 'Failed to save insights', type: 'error' });
        }
    };

    const deletePersona = async (personaId) => {
        setLoading(true);
        try {
            await axios.delete(`/api/interviews/personas/${personaId}`);
            setPersonas(personas.filter(persona => persona._id !== personaId));
            setAlert({ message: 'Persona deleted successfully', type: 'success' });
        } catch (error) {
            console.error('Failed to delete persona:', error);
            setAlert({ message: 'Failed to delete persona', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeAlert = () => {
        setAlert({ message: '', type: '' });
    };

    const handleShowAllKeywords = () => {
        setShowAllKeywords(!showAllKeywords);
    };

    const renderKeywords = () => {
        if (!interview?.insights?.keywords?.length) {
            return (
                <div className="mt-8">
                    <h3 className="font-bold">Keywords</h3>
                    <div className="bg-gray-100 p-4 rounded mt-4">
                        <p className="text-gray-600">No keywords data available yet.</p>
                    </div>
                </div>
            );
        }

        const keywords = showAllKeywords 
            ? interview.insights.keywords 
            : interview.insights.keywords.slice(0, 10);

        return (
            <div className="mt-8">
                <h3 className="font-bold">Keywords</h3>
                <div className="flex flex-wrap gap-2 mt-4">
                    {keywords.map((item, index) => (
                        <span key={index} className="bg-gray-200 px-2 py-1 rounded">
                            {item.keyword} ({item.frequency})
                        </span>
                    ))}
                </div>
                {interview.insights.keywords.length > 10 && (
                    <button 
                        onClick={() => setShowAllKeywords(!showAllKeywords)}
                        className="text-blue-600 mt-2"
                    >
                        {showAllKeywords ? 'Show Less' : 'Show More'}
                    </button>
                )}
            </div>
        );
    };

    const handleDeleteInterview = () => {
        setDeleteModalOpen(true);
    };

    const confirmDeleteInterview = async () => {
        setLoading(true);
        try {
            await axios.delete(`/api/interviews/${interviewId}/delete`);
            setAlert({ message: 'Interview deleted successfully!', type: 'success' });
            history.push(`/projects/${interview.projectId._id}/detail`);
        } catch (error) {
            console.error('Failed to delete interview:', error);
            setAlert({ message: 'Failed to delete interview', type: 'error' });
        } finally {
            setLoading(false);
            setDeleteModalOpen(false);
        }
    };

    const renderMetrics = () => {
        if (!insights || !insights.metrics) return null;

        return (
            <div className="mt-4">
                <h3 className="font-bold">Metrics</h3>
                <div className="grid grid-cols-3 gap-4">
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Total Transcripts</h4>
                        <p>{insights.metrics?.totalTranscripts || 0}</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Average Length</h4>
                        <p>{insights.metrics?.averageLength || 0} characters</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Unique Words</h4>
                        <p>{insights.metrics?.uniqueWords || 0}</p>
                    </div>
                </div>
            </div>
        );
    };

    const renderEngagement = () => {
        if (!insights.engagement) return null;

        return (
            <div className="mt-8">
                <h3 className="font-bold">Engagement Analysis</h3>
                <div className="grid grid-cols-3 gap-4 mt-4">
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Brief Responses</h4>
                        <p>{insights.engagement.shortResponses} responses</p>
                        <p className="text-sm text-gray-600">(Less than 10 words)</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Detailed Responses</h4>
                        <p>{insights.engagement.longResponses} responses</p>
                        <p className="text-sm text-gray-600">(More than 50 words)</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded">
                        <h4 className="font-semibold">Average Response Length</h4>
                        <p>{insights.engagement.averageWordsPerResponse} words</p>
                    </div>
                </div>
            </div>
        );
    };

    const renderEmotionalAnalysis = () => {
        if (!insights.emotions) return null;

        const total = insights.emotions.positiveCount + insights.emotions.negativeCount + insights.emotions.neutralCount;
        
        return (
            <div className="mt-8">
                <h3 className="font-bold">Emotional Analysis</h3>
                <div className="relative pt-1">
                    <div className="flex mb-2 items-center justify-between">
                        <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                Positive ({Math.round(insights.emotions.positiveCount/total * 100)}%)
                            </span>
                        </div>
                        <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">
                                Neutral ({Math.round(insights.emotions.neutralCount/total * 100)}%)
                            </span>
                        </div>
                        <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200">
                                Negative ({Math.round(insights.emotions.negativeCount/total * 100)}%)
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderFeatureAnalysis = () => {
        // Early return with default UI if insights or preferenceIndicators are undefined
        if (!interview?.insights?.preferenceIndicators) {
            return (
                <div className="bg-white rounded-lg shadow p-6 mb-6">
                    <h3 className="text-xl font-semibold mb-4">Feature Analysis</h3>
                    <p className="text-gray-600">No feature analysis data available yet.</p>
                </div>
            );
        }

        const { preferredFeatures = new Map(), missingFeatures = new Map() } = 
            interview.insights.preferenceIndicators;

        // Convert Map to Array for rendering
        const preferredFeaturesArray = Array.from(preferredFeatures || new Map());
        const missingFeaturesArray = Array.from(missingFeatures || new Map());

        return (
            <div className="bg-white rounded-lg shadow p-6 mb-6">
                <h3 className="text-xl font-semibold mb-4">Feature Analysis</h3>
                
                <div className="grid md:grid-cols-2 gap-6">
                    <div>
                        <h4 className="font-medium mb-3">Preferred Features</h4>
                        {preferredFeaturesArray.length > 0 ? (
                            <ul className="space-y-2">
                                {preferredFeaturesArray.map(([feature, count]) => (
                                    <li key={feature} className="flex justify-between">
                                        <span>{feature}</span>
                                        <span className="text-green-600">{count}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-500">No preferred features identified yet.</p>
                        )}
                    </div>

                    <div>
                        <h4 className="font-medium mb-3">Missing Features</h4>
                        {missingFeaturesArray.length > 0 ? (
                            <ul className="space-y-2">
                                {missingFeaturesArray.map(([feature, count]) => (
                                    <li key={feature} className="flex justify-between">
                                        <span>{feature}</span>
                                        <span className="text-red-600">{count}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-500">No missing features identified yet.</p>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderCompetitiveAnalysis = () => {
        if (!interview?.insights?.competitiveAnalysis) {
            return (
                <div className="mt-8">
                    <h3 className="font-bold">Competitive Analysis</h3>
                    <div className="bg-gray-100 p-4 rounded mt-4">
                        <p className="text-gray-600">No competitive analysis data available yet.</p>
                    </div>
                </div>
            );
        }

        const { competitorMentions = {}, comparativeStatements = [] } = interview.insights.competitiveAnalysis;

        return (
            <div className="mt-8">
                <h3 className="font-bold">Competitive Analysis</h3>
                <div className="bg-gray-100 p-4 rounded mt-4">
                    <h4 className="font-semibold">Competitor Mentions</h4>
                    {Object.keys(competitorMentions).length > 0 ? (
                        Object.entries(competitorMentions)
                            .sort(([,a], [,b]) => b - a)
                            .map(([competitor, count]) => (
                                <div key={competitor} className="flex justify-between items-center mt-2">
                                    <span className="capitalize">{competitor}</span>
                                    <span className="bg-blue-200 px-2 rounded">{count} mentions</span>
                                </div>
                            ))
                    ) : (
                        <p className="text-gray-600 mt-2">No competitor mentions recorded yet.</p>
                    )}
                </div>
            </div>
        );
    };

    const renderAIInsights = () => {
        if (!insights?.aiAnalysis) return null;

        const sections = [
            { title: 'Key Behaviors', data: insights.aiAnalysis.behaviors },
            { title: 'Pain Points', data: insights.aiAnalysis.painPoints },
            { title: 'User Needs', data: insights.aiAnalysis.needs },
            { title: 'Opportunities', data: insights.aiAnalysis.opportunities },
            { title: 'Success Stories', data: insights.aiAnalysis.successStories },
            { title: 'Adoption Barriers', data: insights.aiAnalysis.adoptionBarriers },
            { title: 'Recommendations', data: insights.aiAnalysis.recommendations }
        ];

        return (
            <div className="mt-8">
                <h3 className="text-xl font-bold mb-4">Analysis</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {sections.map(section => (
                        <div key={section.title} className="bg-white p-4 rounded-lg shadow">
                            <h4 className="font-semibold text-lg mb-2">{section.title}</h4>
                            <ul className="list-disc pl-5 space-y-2">
                                {section.data?.map((item, index) => (
                                    <li key={index} className="text-gray-700">{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const getFilteredSessions = () => {
        switch (sessionFilter) {
            case 'completed':
                return sessions.filter(session => session.completed);
            case 'incomplete':
                return sessions.filter(session => !session.completed);
            default:
                return sessions;
        }
    };

    const renderSessions = () => {
        return (
            <div className="mt-4">
                <div className="mb-4 flex justify-end">
                    <select
                        value={sessionFilter}
                        onChange={(e) => setSessionFilter(e.target.value)}
                        className="block w-48 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-mint focus:border-mint"
                    >
                        <option value="all">Show All</option>
                        <option value="completed">Completed Only</option>
                        <option value="incomplete">Not Complete Only</option>
                    </select>
                </div>
                <div className="bg-white shadow rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date & Time
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Email
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Incentive Code
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {getFilteredSessions().map((session) => (
                                <tr key={session.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {new Date(session.startDate).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                            session.completed 
                                                ? 'bg-green-100 text-green-800' 
                                                : 'bg-yellow-100 text-yellow-800'
                                        }`}>
                                            {session.completed ? 'Completed' : 'In Progress'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {session.completed ? session.email : '-'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {session.completed ? session.incentiveCode : '-'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => {
                                                setSelectedSession(session);
                                                setIsTranscriptModalOpen(true);
                                            }}
                                            className="text-mint hover:text-mint-dark"
                                        >
                                            View Transcript
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const renderTranscriptModal = () => {
        if (!selectedSession) return null;

        return (
            <Modal 
                isOpen={isTranscriptModalOpen} 
                onClose={() => {
                    setIsTranscriptModalOpen(false);
                    setSelectedSession(null);
                }}
            >
                <div className="max-h-[70vh] overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">Interview Transcript</h2>
                        <div className="flex space-x-2">
                            <input 
                                type="text" 
                                placeholder="Search transcript..." 
                                className="border rounded px-2 py-1"
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <select 
                                className="border rounded px-2 py-1"
                                onChange={(e) => handleFilterByTag(e.target.value)}
                            >
                                <option value="">Filter by tag</option>
                                {availableTags.map(tag => (
                                    <option key={tag} value={tag}>{tag}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {selectedSession.messages.map((message, index) => (
                            <div 
                                key={index} 
                                className={`p-3 rounded-lg relative ${
                                    message.sender === 'user' 
                                        ? 'bg-blue-100 ml-8' 
                                        : 'bg-gray-100 mr-8'
                                }`}
                                onMouseUp={() => handleTextSelection(message._id)}
                            >
                                <div className="flex justify-between items-start">
                                    <p className="text-sm text-gray-600 mb-1">
                                        {message.sender === 'user' ? 'Participant' : 'AI Interviewer'}
                                    </p>
                                    <div className="flex space-x-1">
                                        {message.tags?.map(tag => (
                                            <span key={tag} className="text-xs bg-gray-200 rounded px-2 py-1">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                
                                <p>{message.text}</p>
                                
                                {message.highlights?.map((highlight, i) => (
                                    <div key={i} className="mt-2 text-sm bg-yellow-100 p-2 rounded">
                                        <p className="font-semibold">Highlighted: {highlight.text}</p>
                                        <p className="text-gray-600">{highlight.comment}</p>
                                    </div>
                                ))}
                                
                                <div className="text-xs text-gray-500 mt-1 flex justify-between">
                                    <span>{new Date(message.timestamp).toLocaleString()}</span>
                                    <div className="flex space-x-2">
                                        <button onClick={() => handleAddTag(message._id)}>
                                            Add Tag
                                        </button>
                                        <button onClick={() => handleAddAnnotation(message._id)}>
                                            Add Note
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        );
    };

    const renderOverviewTab = () => {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
                {/* Key Insights Section */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Key Insights</h3>
                    <ul className="list-disc pl-5 space-y-2">
                        {insights?.aiAnalysis?.recommendations?.map((insight, index) => (
                            <li key={index} className="text-gray-700">{insight}</li>
                        ))}
                    </ul>
                </div>

                {/* Metrics Section */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Analysis Metrics</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-gray-600">Total Transcripts</p>
                            <p className="text-2xl font-bold">{insights?.metrics?.totalTranscripts || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Average Length</p>
                            <p className="text-2xl font-bold">{insights?.metrics?.averageLength || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Unique Words</p>
                            <p className="text-2xl font-bold">{insights?.metrics?.uniqueWords || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Brief Responses</p>
                            <p className="text-2xl font-bold">{insights?.engagement?.shortResponses || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Detailed Responses</p>
                            <p className="text-2xl font-bold">{insights?.engagement?.longResponses || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Avg Response Length</p>
                            <p className="text-2xl font-bold">{insights?.engagement?.averageWordsPerResponse || 0}</p>
                        </div>
                    </div>
                </div>

                {/* Demographics Section */}
                <div className="bg-white p-6 rounded-lg shadow col-span-2">
                    <h3 className="text-xl font-semibold mb-4">Participant Demographics</h3>
                    <div className="grid grid-cols-3 gap-8">
                        {/* Age Distribution */}
                        <div>
                            <h4 className="font-semibold mb-2">Age Distribution</h4>
                            <Bar 
                                data={ageData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: { display: false },
                                        title: { display: false }
                                    },
                                    scales: {
                                        y: { beginAtZero: true }
                                    }
                                }}
                            />
                        </div>

                        {/* Occupation Distribution */}
                        <div>
                            <h4 className="font-semibold mb-2">Occupations</h4>
                            <Bar 
                                data={occupationData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: { display: false },
                                        title: { display: false }
                                    },
                                    scales: {
                                        y: { beginAtZero: true }
                                    }
                                }}
                            />
                        </div>

                        {/* City Distribution */}
                        <div>
                            <h4 className="font-semibold mb-2">Cities</h4>
                            <Bar 
                                data={cityData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: { display: false },
                                        title: { display: false }
                                    },
                                    scales: {
                                        y: { beginAtZero: true }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAnalysisTab = () => {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Suggestions Section */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Suggestions</h3>
                    <ul className="list-disc pl-5 space-y-2">
                        {insights?.suggestions?.map((suggestion, index) => (
                            <li key={index} className="text-gray-700">{suggestion}</li>
                        ))}
                    </ul>
                </div>

                {/* Topics Section */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Topics</h3>
                    <div className="flex flex-wrap gap-2">
                        {insights?.topics?.map((topic, index) => (
                            <span key={index} className="bg-gray-100 px-3 py-1 rounded-full text-sm">
                                {topic}
                            </span>
                        ))}
                    </div>
                </div>

                {/* Emotional Analysis */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Emotional Analysis</h3>
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            <p className="text-gray-600">Positive</p>
                            <p className="text-2xl font-bold">{insights?.emotions?.positiveCount || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Negative</p>
                            <p className="text-2xl font-bold">{insights?.emotions?.negativeCount || 0}</p>
                        </div>
                        <div>
                            <p className="text-gray-600">Neutral</p>
                            <p className="text-2xl font-bold">{insights?.emotions?.neutralCount || 0}</p>
                        </div>
                    </div>
                </div>

                {/* Feature Analysis */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Feature Analysis</h3>
                    <div>
                        <h4 className="font-semibold mb-2">Most Discussed Features</h4>
                        {Object.entries(insights?.featureAnalysis?.mostDiscussedFeatures || {}).map(([feature, count], index) => (
                            <div key={index} className="flex justify-between mb-1">
                                <span>{feature}</span>
                                <span>{count}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Competitor Analysis */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h3 className="text-xl font-semibold mb-4">Competitor Analysis</h3>
                    <div>
                        <h4 className="font-semibold mb-2">Competitor Mentions</h4>
                        {Object.entries(insights?.competitiveAnalysis?.competitorMentions || {}).map(([competitor, count], index) => (
                            <div key={index} className="flex justify-between mb-1">
                                <span>{competitor}</span>
                                <span>{count}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const renderKeyInsightsTab = () => (
        <div className="space-y-8">
            <div className="bg-white rounded-lg shadow p-6">
                <h3 className="font-bold text-lg mb-4">Suggestions</h3>
                <ul className="space-y-2">
                    {insights?.suggestions?.map((suggestion, index) => (
                        <li key={index} className="flex items-start">
                            <span className="mr-2">•</span>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="bg-white rounded-lg shadow p-6">
                {renderAIInsights()}
            </div>
            <div className="bg-white rounded-lg shadow p-6">
                {renderKeywords()}
            </div>
            <div className="bg-white rounded-lg shadow p-6">
                <h3 className="font-bold">Topics</h3>
                <div className="flex flex-wrap gap-2 mt-4">
                    {insights?.topics?.map((topic, index) => (
                        <span key={index} className="px-3 py-1 bg-cyan-500 rounded-full text-white">
                            {topic}
                        </span>
                    ))}
                </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
                {renderEmotionalAnalysis()}
            </div>
            <div className="bg-white rounded-lg shadow p-6">
                {renderFeatureAnalysis()}
            </div>
            <div className="bg-white rounded-lg shadow p-6">
                {renderCompetitiveAnalysis()}
            </div>
           
        </div>
    );

    const renderPersonasTab = () => (
        <div className="space-y-6">
            <div className="flex justify-between items-center p-6">
                <h3 className="font-bold text-lg">Generated Personas</h3>
                <button
                    className="bg-mint text-white py-2 px-4 rounded hover:bg-mint-dark"
                    onClick={generatePersona}
                    disabled={loading}
                >
                    {loading ? <Spinner /> : 'Generate New Persona'}
                </button>
            </div>
            {personas.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
                    {personas.map((persona, index) => (
                        <PersonaCard key={index} persona={persona} onDelete={deletePersona} />
                    ))}
                </div>
            ) : (
                <p className="text-gray-500">No personas generated yet.</p>
            )}
        </div>
    );

    const renderIntegrationsTab = () => {
        return (
            <div className="space-y-6">
                {/* Reddit Integration Section */}
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <h3 className="text-lg font-semibold mb-4">Reddit Threads</h3>
                    
                    {/* Add Reddit URL Form */}
                    <form onSubmit={handleAddRedditThread} className="mb-6">
                        <div className="flex space-x-2">
                            <input
                                type="text"
                                value={redditUrl}
                                onChange={(e) => setRedditUrl(e.target.value)}
                                placeholder="Paste Reddit thread URL here"
                                className="flex-grow px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-mint focus:border-transparent"
                                disabled={isAddingReddit}
                            />
                            <button
                                type="submit"
                                disabled={isAddingReddit}
                                className={`px-4 py-2 bg-mint text-white rounded hover:bg-mint-dark transition-colors
                                    ${isAddingReddit ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isAddingReddit ? (
                                    <>
                                        <Spinner className="mr-2" />
                                        Adding...
                                    </>
                                ) : (
                                    'Add Thread'
                                )}
                            </button>
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                            Paste the URL of a Reddit thread to analyze its comments
                        </p>
                    </form>

                    {/* Reddit Thread List */}
                    {renderRedditList()}
                </div>

                {/* More Integrations Coming Soon Section */}
                <div className="bg-gray-50 border border-gray-200 rounded-lg p-6 text-center">
                    <div className="flex flex-col items-center justify-center space-y-2">
                        <svg 
                            className="w-12 h-12 text-white" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M13 10V3L4 14h7v7l9-11h-7z"
                            />
                        </svg>
                        <h3 className="text-lg font-medium text-gray-900">More Integrations Coming Soon</h3>
                        <p className="text-gray-500">
                            We're working on adding more integrations to help you gather insights from various sources.
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'overview':
                return renderOverviewTab();
            case 'sessions':
                return renderSessions();
            case 'keyInsights':
                return renderKeyInsightsTab();
            case 'personas':
                return renderPersonasTab();
            case 'integrations':
                return renderIntegrationsTab();
            default:
                return renderOverviewTab();
        }
    };

    const renderDemographics = () => {
        return (
            <div className="mt-8">
                <h3 className="text-xl font-bold mb-4">Demographics</h3>
                
                <div className="grid grid-cols-2 gap-8">
                    {/* Age Distribution Chart */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Age Distribution</h4>
                        <div className="h-64">
                            <Bar data={ageData} options={{ maintainAspectRatio: false }} />
                        </div>
                    </div>

                    {/* Occupation Distribution Chart */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Occupation Distribution</h4>
                        <div className="h-64">
                            <Bar data={occupationData} options={{ maintainAspectRatio: false }} />
                        </div>
                    </div>
                </div>

                {/* City Distribution */}
                <div className="mt-8 bg-white p-4 rounded-lg shadow">
                    <h4 className="text-lg font-semibold mb-2">City Distribution</h4>
                    <div className="grid grid-cols-3 gap-4">
                        {Object.entries(demographicData.cities).map(([city, count]) => (
                            <div key={city} className="flex justify-between p-2 bg-gray-50 rounded">
                                <span className="font-medium">{city}</span>
                                <span className="text-gray-600">{count}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    // Add Reddit thread to interview
    const addRedditThread = async () => {
        setIsAddingReddit(true);
        try {
            const response = await axios.post(`/api/reddit/interview/${interviewId}`, {
                url: redditUrl
            });
            
            // Update interview with new Reddit data
            setInterview(prev => ({
                ...prev,
                redditData: [...(prev.redditData || []), response.data.data]
            }));
            
            setRedditUrl('');
            setAlert({
                message: 'Reddit thread added successfully!',
                type: 'success'
            });
        } catch (error) {
            console.error('Failed to add Reddit thread:', error);
            setAlert({
                message: 'Failed to add Reddit thread. Please try again.',
                type: 'error'
            });
        } finally {
            setIsAddingReddit(false);
        }
    };

    const renderRedditComments = (thread) => {
        return (
            <div className="max-h-[70vh] overflow-y-auto">
                <div className="mb-4 border-b pb-4">
                    <h3 className="text-xl font-bold">{thread.threadTitle}</h3>
                    <div className="text-sm text-gray-600">
                        <p>Author: {thread.threadAuthor}</p>
                        <p>Created: {new Date(thread.threadCreated).toLocaleDateString()}</p>
                        <a 
                            href={thread.threadUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-mint hover:text-mint-dark"
                        >
                            View on Reddit →
                        </a>
                    </div>
                </div>
                <div className="space-y-4">
                    {thread.rawComments.map((comment, index) => (
                        <div key={index} className="p-3 bg-gray-50 rounded-lg" style={{ marginLeft: `${comment.depth * 20}px` }}>
                            <div className="flex justify-between text-sm text-gray-600 mb-1">
                                <span>{comment.author}</span>
                                <span>Score: {comment.score}</span>
                            </div>
                            <p className="text-gray-800">{comment.body}</p>
                            <div className="text-xs text-gray-500 mt-1">
                                {new Date(comment.created_utc).toLocaleString()}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderDataSourcesModal = () => {
        return (
            <Modal
                isOpen={isDataSourcesModalOpen}
                onClose={() => setIsDataSourcesModalOpen(false)}
                className="w-full max-w-2xl"
            >
                <div className="p-4">
                    <h2 className="text-xl font-bold mb-4">Select Data Sources</h2>
                    <div className="space-y-4">
                        {/* Interview Sessions */}
                        <div className="p-3 bg-gray-50 rounded-lg">
                            <label className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    checked={selectedDataSources.interviews}
                                    onChange={(e) => setSelectedDataSources(prev => ({
                                        ...prev,
                                        interviews: e.target.checked
                                    }))}
                                    className="form-checkbox h-5 w-5 text-mint rounded"
                                />
                                <div>
                                    <div className="font-medium">Interview Sessions</div>
                                    <div className="text-sm text-gray-500">
                                        {completedSessions} {completedSessions === 1 ? 'session' : 'sessions'} available
                                    </div>
                                </div>
                            </label>
                        </div>

                        {/* Reddit Data */}
                        {interview?.redditData?.length > 0 && (
                            <div className="p-3 bg-gray-50 rounded-lg">
                                <label className="flex items-center space-x-3">
                                    <input
                                        type="checkbox"
                                        checked={selectedDataSources.reddit}
                                        onChange={(e) => setSelectedDataSources(prev => ({
                                            ...prev,
                                            reddit: e.target.checked
                                        }))}
                                        className="form-checkbox h-5 w-5 text-mint rounded"
                                    />
                                    <div>
                                        <div className="font-medium">Reddit Threads</div>
                                        <div className="text-sm text-gray-500">
                                            {interview.redditData.length} {interview.redditData.length === 1 ? 'thread' : 'threads'} available
                                        </div>
                                    </div>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    };

    // Add this helper function to show selected data sources
    const getSelectedDataSourcesText = () => {
        const sources = [];
        if (selectedDataSources.interviews) sources.push('Interviews');
        if (selectedDataSources.reddit) sources.push('Reddit');
        return sources.length > 0 ? sources.join(' & ') : 'No sources selected';
    };

    // Add this function to handle Reddit URL submission
    const handleAddRedditThread = async (e) => {
        e.preventDefault();
        if (!redditUrl.trim()) {
            setAlert({
                message: 'Please enter a Reddit URL',
                type: 'error'
            });
            return;
        }

        setIsAddingReddit(true);
        try {
            const response = await axios.post(`/api/reddit/interview/${interviewId}`, {
                url: redditUrl
            });

            setInterview(prev => ({
                ...prev,
                redditData: [...(prev.redditData || []), response.data.data]
            }));

            setRedditUrl('');
            setAlert({
                message: 'Reddit thread added successfully',
                type: 'success'
            });
        } catch (error) {
            console.error('Failed to add Reddit thread:', error);
            setAlert({
                message: error.response?.data?.error || 'Failed to add Reddit thread',
                type: 'error'
            });
        } finally {
            setIsAddingReddit(false);
        }
    };

    const renderRedditList = () => {
        if (!interview?.redditData?.length) {
            return (
                <div className="text-center py-6 text-gray-500">
                    No Reddit threads added yet
                </div>
            );
        }

        return (
            <div className="space-y-4">
                {interview.redditData.map((thread, index) => (
                    <div 
                        key={index}
                        className="border rounded-lg p-4 hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                            setSelectedRedditThread(thread);
                            setIsRedditModalOpen(true);
                        }}
                    >
                        <h4 className="font-medium text-gray-900">{thread.threadTitle}</h4>
                        <div className="mt-2 flex justify-between text-sm text-gray-500">
                            <span>{thread.commentCount} comments</span>
                            <span>{new Date(thread.threadCreated).toLocaleDateString()}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderInterviewSelector = () => {
        if (!projectInterviews.length) {
            return (
                <div className="w-full block bg-canaryGreen text-white text-sm rounded p-6 rounded-none">
                    No interviews available
                </div>
            );
        }

        return (
            <select 
                className="w-full block bg-BGGrey-BORDER text-BGGrey-HEADER text-sm rounded p-6 rounded-none"
                value={interview?._id}
                onChange={(e) => {
                    if (e.target.value !== interview?._id) {
                        window.location.href = `/interviews/${e.target.value}`;
                    }
                }}
            >
                {projectInterviews.map(int => (
                    <option key={int._id} value={int._id}>
                        {int.name}
                    </option>
                ))}
            </select>
        );
    };

    // Add helper function for initials
    const getInitials = (firstName, lastName) => {
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    // Handler for text selection and highlighting
    const handleTextSelection = (messageId) => {
        const selection = window.getSelection();
        const selectedText = selection.toString().trim();

        if (selectedText) {
            const range = selection.getRangeAt(0);
            const span = document.createElement('span');
            span.className = 'bg-yellow-200';
            range.surroundContents(span);

            // Save the highlight to the session
            const updatedSession = { ...selectedSession };
            const messageIndex = updatedSession.messages.findIndex(m => m._id === messageId);
            
            if (messageIndex !== -1) {
                if (!updatedSession.messages[messageIndex].highlights) {
                    updatedSession.messages[messageIndex].highlights = [];
                }
                
                updatedSession.messages[messageIndex].highlights.push({
                    text: selectedText,
                    createdAt: new Date()
                });
                
                setSelectedSession(updatedSession);
                // TODO: Save highlights to backend
            }
        }
    };

    // Handler for adding tags to messages
    const handleAddTag = async (messageId) => {
        const tag = prompt('Enter tag:'); // Replace with a proper modal/form
        if (!tag) return;

        try {
            const updatedSession = { ...selectedSession };
            const messageIndex = updatedSession.messages.findIndex(m => m._id === messageId);
            
            if (messageIndex !== -1) {
                if (!updatedSession.messages[messageIndex].tags) {
                    updatedSession.messages[messageIndex].tags = [];
                }
                
                updatedSession.messages[messageIndex].tags.push(tag);
                
                // Add to available tags if it's new
                if (!availableTags.includes(tag)) {
                    setAvailableTags([...availableTags, tag]);
                }
                
                setSelectedSession(updatedSession);
                // TODO: Save tags to backend
            }
        } catch (error) {
            console.error('Failed to add tag:', error);
        }
    };

    // Handler for adding annotations
    const handleAddAnnotation = async (messageId) => {
        const comment = prompt('Enter annotation:'); // Replace with a proper modal/form
        if (!comment) return;

        try {
            const updatedSession = { ...selectedSession };
            const messageIndex = updatedSession.messages.findIndex(m => m._id === messageId);
            
            if (messageIndex !== -1) {
                if (!updatedSession.messages[messageIndex].highlights) {
                    updatedSession.messages[messageIndex].highlights = [];
                }
                
                updatedSession.messages[messageIndex].highlights.push({
                    text: '',
                    comment,
                    createdAt: new Date()
                });
                
                setSelectedSession(updatedSession);
                // TODO: Save annotation to backend
            }
        } catch (error) {
            console.error('Failed to add annotation:', error);
        }
    };

    // Handler for search
    const handleSearch = (value) => {
        setSearchTerm(value);
        // Filter messages based on search term
        if (selectedSession) {
            const filteredMessages = selectedSession.messages.filter(message =>
                message.text.toLowerCase().includes(value.toLowerCase())
            );
            // Update UI to highlight or filter messages
            // This depends on how you want to handle search results
        }
    };

    // Handler for tag filtering
    const handleFilterByTag = (tag) => {
        setSelectedTag(tag);
        // Filter messages based on selected tag
        if (selectedSession) {
            const filteredMessages = selectedSession.messages.filter(message =>
                message.tags?.includes(tag)
            );
            // Update UI to show only messages with selected tag
            // This depends on how you want to handle filtered results
        }
    };

    if (!interview) {
        return <div>Loading interview details...</div>;
    }

    return (
        <div className="h-[calc(100vh-0rem)] pt-16 flex rounded-tl-md bg-BGGrey ">
            {/* Left Navigation - Fixed */}
            <div className="w-64 bg-BGGrey pt-8 fixed left-0 top-16 bottom-0 border-r-2 border-BGGrey">
                

                {/* Back to Home Link */}
                <div className="text-sm">
                    <Link 
                        to="/projects"
                        className="w-full text-sm flex items-center px-6 py-3 text-BGGrey-HEADER hover:bg-gray-700 hover:text-white"
                    >
                         <HomeIcon className="w-5 h-5 mr-3" color="currentColor" />
                        <span className="font-medium">Back to Home</span>
                    </Link>
                </div>

                {/* Project Context */}
                <div className="py-3">
                 
                    <Link 
                        to={`/projects/${interview?.projectId?._id}/detail`}
                        className="px-4 relative block text-BGGrey-HEADER hover:text-mint items-center mb-2 float-left"
                    >
                        <p className='mr-2 capitalize text-xs block w-full clear-both float-left text-sm'>PROJECT: </p>
                        <span className="font-medium text-base clear-both float-left text-sm">{interview?.projectId?.name}</span>
                       
                    </Link>
                    <span className="clear-both w-full float-left">{renderInterviewSelector()}</span>
                </div>

                {/* Navigation Items */}
                <nav className="relative mt-6 float-left w-full pr-6 pl-6">
                    {navItems.map(item => (
                        <button
                            key={item.id}
                            onClick={() => setActiveTab(item.id)}
                            className={`w-full rounded-full flex items-center align-middle px-6 py-3 text-left text-sm
                                ${activeTab === item.id 
                                    ? 'bg-BGGrey-BORDER text-BGGrey-DARK' 
                                    : 'text-BGGrey-NAV hover:bg-gray-700 hover:text-canaryGreen'}`}
                        >
                            <div className="w-6 h-6 mr-3 flex items-center justify-center">
                                {item.icon}
                            </div>
                            <span className="font-medium">{item.label}</span>
                        </button>
                    ))}
                </nav>
            </div>

           
           
            {/* Main Content Area */}
            <div className="flex-1 ml-64 flex flex-col h-full rounded-tl-md bg-BGGrey">
                {/* Header Section - Doesn't scroll */}
                <div className="p-8 flex-none">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{interview?.name}</h1>
                            <p className="text-gray-500">
                                {interview?.projectId?.organization} • {interview?.projectId?.name}
                            </p>
                        </div>
                        <div className="flex gap-4 items-center">
                            <button
                                onClick={() => setDeleteModalOpen(true)}
                                className="bg-red-50 text-red-600 px-4 py-2 rounded-lg hover:bg-red-100"
                            >
                                 <DeleteIcon className="w-5 h-5 mr-2" color="red" />
                            </button>
                            <div className="relative">
                                <button
                                    onClick={handleCopyLink}
                                    className="bg-gray-100 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-200 text-sm flex items-center border-2 border-BGGrey-BORDER hover:border-canary"
                                >
                                    {isCopied ? (
                                        <>
                                            <span className="mr-2">✓</span>
                                            <span>Copied!</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="mr-2">🔗</span>
                                            <span>Copy Link</span>
                                        </>
                                    )}
                                </button>
                            </div>
                            <button
                                onClick={generateInsights}
                                className="bg-canary z-0 hover:bg-yellow-600 text-black py-2 px-4 rounded-full"
                                disabled={isGeneratingInsights}
                            >
                                {isGeneratingInsights ? 'Generating...' : 'Generate Insights'}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Content Area - Scrollable */}
                <div className="flex-1 overflow-y-auto p-8 pt-0">
                    <div className="bg-white rounded-lg shadow">
                        {renderActiveTab()}
                    </div>
                    {renderSentimentChart()}
                    <InsightEditor interviewId={interviewId} />
                </div>
            </div>

            {/* Keep existing modals */}
            {renderTranscriptModal()}
            <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                {/* ... existing delete modal content ... */}
            </Modal>
            {/* ... other existing modals ... */}
        </div>
    );
}

// Add mapStateToProps
const mapStateToProps = ({ auth }) => {
    return { auth };
};

// Connect the component to Redux
export default connect(mapStateToProps)(InterviewDetail);
