// LoginPage.js
import React from 'react';
import { Link} from 'react-router-dom';
import Logo from './icons/logo';

const LoginPage = () => {
    return (
        <div className="justify-center min-h-screen bg-BGGrey-HEADER">
            <div className='m-auto w-300 relative justify-center items-center'>
                <div className='justify-center mb-32 items-center'> 
                    <Link
                            to="/"
                            className=" justify-center items-center text-center rounded-lg px-2 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900"
                        >
                            <div className='m-auto w-full relative justify-center items-center '>
                                <div className='w-300 h-300 m-auto block'>
                                    <Logo width="176" height="68" />
                                </div>
                            </div> 

                    </Link>

                </div>
                <div className="relateive m-auto p-8 rounded-lg shadow-lg w-full max-w-md h-300">
                    <h2 className="text-2xl mb-6 text-center text-white">Log In</h2>
                    <div className="space-y-4">
                       
                       <h1>Coming soon</h1>
                       <p>Register your interest on the <a href="https://flockhub.co.uk">Flock Hub website</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
