import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

function InsightEditor({ interviewId, auth }) {
    const [insight, setInsight] = useState('');
    const [tags, setTags] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSaveInsight = async () => {
        try {
            const response = await axios.post(`/api/interviews/${interviewId}/insights`, {
                notes: insight,
                keyFindings: {
                    title: 'Custom Insight',
                    description: insight,
                    tags: tags.split(',').map(tag => tag.trim())
                }
            }, {
                headers: { Authorization: `Bearer ${auth.token}` }
            });

            setSuccess('Insight saved successfully!');
            setInsight('');
            setTags('');
        } catch (error) {
            setError('Failed to save insight.');
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow mt-4">
            <h3 className="text-lg font-bold mb-2">Add Insight</h3>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <textarea
                className="w-full p-2 border rounded mb-2"
                rows="4"
                placeholder="Write your insight here..."
                value={insight}
                onChange={(e) => setInsight(e.target.value)}
            />
            <input
                type="text"
                className="w-full p-2 border rounded mb-2"
                placeholder="Tags (comma separated)"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
            />
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleSaveInsight}
            >
                Save Insight
            </button>
        </div>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(InsightEditor); 